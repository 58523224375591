<template>
    <component :is="currentComponent"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "Events",
    computed: {
        currentComponent() {
            return 'ViewAllEventsComponent'
        },
    },
    components: {
        ViewAllEventsComponent: defineAsyncComponent(() =>
            import("@/views/settings/events/ViewAllEvents")
        ),
    }
}
</script>